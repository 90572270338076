import { useMemo } from "react"
import { Lat, Lng } from "@geome/types"

export const useGoogleDirectionsLink = ({
  lat,
  lng,
  placeId,
}: {
  lat: Lat
  lng: Lng
  placeId?: string
}): string =>
  useMemo(() => {
    const url = new URL("https://www.google.com/maps/dir/?api=1")
    url.searchParams.set("destination", `${lat},${lng}`)

    if (placeId) {
      url.searchParams.set("destination_place_id", placeId)
    }

    return url.toString()
  }, [lat, lng, placeId])
