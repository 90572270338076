import React from "react"
import { Search as GenericSearch } from "@geome/react-components-next/lib/components/search/search"
import { RecentSearches } from "@geome/react-components-next/lib/components/search/recentSearches"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { Pin } from "../icons/pin"

export const Search = (): JSX.Element => (
  <BlockScopingContextProvider block="search">
    <CSSCustomPropertyContextProvider properties={{ "--error-colour": "#D51E20" }}>
      <div className="search__wrapper">
        <GenericSearch
          completionProps={{
            hasGeolocation: true,
            renderPreSearchContent: (onSelect, atom) => (
              <RecentSearches
                renderIcon={() => <Pin />}
                onSelect={onSelect}
                selectionIntentAtom={atom}
              />
            ),
            captureRecentSearches: true,
          }}
          renderCompletions={{
            completions: {
              google: ({ description }) => (
                <>
                  <Pin />
                  <span>{description}</span>
                </>
              ),
            },
          }}
        />
      </div>
    </CSSCustomPropertyContextProvider>
  </BlockScopingContextProvider>
)
