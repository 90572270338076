import { selectorFamily } from "@geome/recoil"
import {
  GooglePlacesResponse,
  OpeningHoursSchema,
  PlacesReview,
  TNTSportLocation,
} from "../../types"
import parseGooglePlacesPeriods from "google-places-periods-parser"
import { LocationsPoolAtomFamily } from "@geome/recoil/dist/feature/locations/atoms"

export const PlacesDetailsSelectorFamily = selectorFamily<
  GooglePlacesResponse | null,
  { locationId: TNTSportLocation["id"] }
>({
  key: "PlacesDetailsSelectorFamily",
  get:
    ({ locationId }) =>
    async () => {
      const details = await fetch(`api/v2/locations/${locationId}/place_details`)
      const response = (await details.json()) as GooglePlacesResponse

      if (response.status !== "OK") return null
      return response
    },
})

export const ReviewsSelectorFamily = selectorFamily<
  { reviews: PlacesReview[]; overallRating?: number },
  { locationId: TNTSportLocation["id"] }
>({
  key: "ReviewsSelectorFamily",
  get:
    ({ locationId }) =>
    async ({ get }) => {
      const placesDetails = get(PlacesDetailsSelectorFamily({ locationId }))
      return {
        reviews: placesDetails?.result?.reviews ?? [],
        overallRating: placesDetails?.result?.rating,
      }
    },
})

export const ContactDetailsSelectorFamily = selectorFamily<
  {
    phone?: { formatted: string; international: string }
    website?: string
    facebook?: string
  } | null,
  { locationId: TNTSportLocation["id"] }
>({
  key: "ContactDetailsSelectorFamily",
  get:
    ({ locationId }) =>
    async ({ get }) => {
      const placesDetails = get(PlacesDetailsSelectorFamily({ locationId }))
      const locationPool = get(LocationsPoolAtomFamily({ resource: "locations" }))
      const location = locationPool[locationId] as TNTSportLocation | undefined

      const phoneNumber = placesDetails?.result?.formatted_phone_number
      const internationalPhoneNumber = placesDetails?.result?.international_phone_number
      const website = placesDetails?.result?.website

      if (!location?.facebook_url && !phoneNumber && !internationalPhoneNumber && !website)
        return null

      let result = {}

      if (phoneNumber && internationalPhoneNumber)
        result = {
          ...result,
          phone: { formatted: phoneNumber, international: internationalPhoneNumber },
        }
      if (website) result = { ...result, website }
      if (location?.facebook_url) result = { ...result, facebook: location.facebook_url }

      return result
    },
})

export const PlacesOpeningHoursSelectorFamily = selectorFamily<
  OpeningHoursSchema | null,
  { locationId: TNTSportLocation["id"] }
>({
  key: "PlacesOpeningHoursSelectorFamily",
  get:
    ({ locationId }) =>
    async ({ get }) => {
      const placesDetails = get(PlacesDetailsSelectorFamily({ locationId }))
      if (!placesDetails || !placesDetails.result?.opening_hours) return null
      const openingHours = parseGooglePlacesPeriods(placesDetails.result.opening_hours.periods)
      return openingHours as OpeningHoursSchema
    },
})

export const PlacesImageSrcSelectorFamily = selectorFamily<
  string | null,
  { locationId: TNTSportLocation["id"] }
>({
  key: "PlacesImageSrcSelectorFamily",
  get:
    ({ locationId }) =>
    async ({ get }) => {
      const placesDetails = get(PlacesDetailsSelectorFamily({ locationId }))

      if (
        !placesDetails ||
        !placesDetails.result ||
        !placesDetails.result.photos ||
        placesDetails.result.photos.length === 0
      )
        return null

      const firstPhoto = placesDetails.result.photos[0]
      const maxWidth = 400

      const imageSrc = `/api/v2/google_place_photos?photo_reference=${encodeURIComponent(
        firstPhoto.photo_reference
      )}&maxwidth=${maxWidth}`

      return imageSrc
    },
})
