import React, { ReactElement, useMemo } from "react"
import { TNTSportLocation } from "../../../types"
import { PropertiesSection } from "@geome/react-components-next/lib/components/properties"
import { icons } from "../../icons/images"
import { useMobileCollapseSection } from "../../../hooks/useMobileCollapseSection"

type FacilitiesSectionProps = {
  location: TNTSportLocation
}

export const FacilitiesSection = ({ location }: FacilitiesSectionProps): ReactElement => {
  const iconImages = useMemo(
    () =>
      Object.fromEntries(Object.entries(icons).map(([key, value]) => [`facilities_${key}`, value])),
    []
  )

  const sectionProps = useMobileCollapseSection()

  return (
    <PropertiesSection
      location={location}
      countryCode="en_GB"
      sectionName="facilities"
      configPath="info_window."
      icons={iconImages}
      renderNullIfEmpty
      sectionProps={sectionProps}
    />
  )
}
