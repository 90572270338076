import React, { ReactElement, useMemo } from "react"
import { PropertiesIconList } from "@geome/react-components-next/lib/components/properties/iconList"
import { useConfig } from "@geome/react-next"
import { TNTSportLocation } from "../../types"
import { icons } from "../icons/sportImages"

type SportsIconListProps = {
  location: TNTSportLocation
  maxIcons?: number
}

export const SportsIconList = ({
  location,
  maxIcons = 6,
}: SportsIconListProps): ReactElement | null => {
  const enabled = useConfig<string[]>("info_window.sections.favourite_sports.enabled", false)

  const enabledProperties = useMemo(
    () =>
      enabled.filter((property) => {
        const [group, value] = property.split(".")
        return location[group].includes(value)
      }),
    [enabled, location]
  )

  const sportsIcons = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(icons).map(([key, value]) => [`favourite_sports_${key}`, value])
      ),
    []
  )

  return (
    <PropertiesIconList
      className="sports-icon-list"
      properties={enabledProperties}
      countryCode="en_GB"
      maxIcons={maxIcons}
      icons={sportsIcons}
    />
  )
}
