import React, { ReactElement } from "react"

export const Pin = (): ReactElement => (
  <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.37437 0.615234C4.95521 0.615234 1.36035 4.17102 1.36035 8.54214C1.36035 14.4873 9.37437 23.2635 9.37437 23.2635C9.37437 23.2635 17.3884 14.4873 17.3884 8.54214C17.3884 4.17102 13.7935 0.615234 9.37437 0.615234Z"
      fill="currentColor"
      stroke="#FF28FF"
    />
  </svg>
)
