import AmazonPremierLeague from "../../../images/icons/amazon_premier_league_pass.svg"
import BeerGarden from "../../../images/icons/beer_garden.svg"
import FamilyFriendly from "../../../images/icons/family_friendly.svg"
import LateNight from "../../../images/icons/late_night.svg"
import LiveMusic from "../../../images/icons/live_music.svg"
import OutdoorScreen from "../../../images/icons/outdoor_screen.svg"
import PayPerView from "../../../images/icons/pay_per_view.svg"
import PetFriendly from "../../../images/icons/pet_friendly.svg"
import PremierLeaguePlus from "../../../images/icons/premier_league_plus.svg"
import ServesFood from "../../../images/icons/serves_food.svg"
import WiFi from "../../../images/icons/wifi.svg"
import Facebook from "../../../images/icons/facebook.svg"
import Hotel from "../../../images/icons/hotel.svg"
import TNTSports1Channel from "../../../images/icons/tnt_sports_1.svg"
import TNTSports2Channel from "../../../images/icons/tnt_sports_2.svg"
import TNTSports3Channel from "../../../images/icons/tnt_sports_3.svg"
import TNTSports4Channel from "../../../images/icons/tnt_sports_4.svg"

export const icons = {
  amazon_premier_league: AmazonPremierLeague,
  beer_garden: BeerGarden,
  family_friendly: FamilyFriendly,
  hotel: Hotel,
  late_night: LateNight,
  live_music: LiveMusic,
  outdoor_screen: OutdoorScreen,
  pay_per_view: PayPerView,
  pet_friendly: PetFriendly,
  premier_league_plus: PremierLeaguePlus,
  serves_food: ServesFood,
  wifi: WiFi,
  // these bt sport keys needs replaced when data is updated
  bt_sport_1: TNTSports1Channel,
  bt_sport_2: TNTSports2Channel,
  bt_sport_3: TNTSports3Channel,
  bt_sport_4: TNTSports4Channel,
  bt_sport_espn: TNTSports4Channel,
}

export {
  AmazonPremierLeague,
  BeerGarden,
  Facebook,
  Hotel,
  LateNight,
  LiveMusic,
  OutdoorScreen,
  PayPerView,
  PetFriendly,
  PremierLeaguePlus,
  ServesFood,
  WiFi,
}
