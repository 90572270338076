import { selector } from "@geome/recoil"
import { GroupedWhatsOnItems, WhatsOnItem, WhatsOnResponse } from "../../types"
import { whatsOnRequestBumpAtom, whatsOnViewAtom } from "./atoms"
import {
  isEqual,
  startOfDay,
  isToday,
  isThisWeek,
  isWithinInterval,
  startOfToday,
  isTomorrow,
  isPast,
  add,
} from "date-fns"
import { parseDate } from "../../utils/parseDate"

export const whatsOnRequestSelector = selector<WhatsOnResponse | null>({
  key: "whatsOnRequestSelector",
  get: async ({ get }) => {
    get(whatsOnRequestBumpAtom)
    const response = await fetch("api/v2/whats_on")
    return response.status === 200 ? ((await response.json()) as WhatsOnResponse) : null
  },
})

export const whatsOnHasErroredSelector = selector<boolean>({
  key: "whatsOnHasErroredSelector",
  get: async ({ get }) => get(whatsOnRequestSelector) === null,
})

export const filteredWhatsOnItemsSelector = selector<WhatsOnItem[]>({
  key: "filteredWhatsOnItemsSelector",
  get: async ({ get }) => {
    const view = get(whatsOnViewAtom)
    const allWhatsOn = get(whatsOnRequestSelector)
    const list = allWhatsOn?.liveEventsList || []

    return list.filter(({ startDate, endDate, dontMissFlag }) => {
      const eventDate = parseDate(startDate)
      if (endDate && isPast(parseDate(endDate))) return false

      switch (view) {
        case "today":
          return isToday(eventDate)
        case "week":
          return isThisWeek(eventDate, { weekStartsOn: 1 })
        case "thirty":
          return isWithinInterval(eventDate, {
            start: startOfToday(),
            end: add(startOfToday(), { days: 31 }),
          })
        case "dont_miss":
          return dontMissFlag
        default:
          return true
      }
    })
  },
})

export const groupedWhatsOnItemsSelector = selector<GroupedWhatsOnItems[]>({
  key: "groupedWhatsOnItemsSelector",
  get: ({ get }) => {
    const filteredEvents = get(filteredWhatsOnItemsSelector)
    const dayList: GroupedWhatsOnItems[] = []

    filteredEvents.forEach((whatsOnEvent) => {
      const dayStartDate = startOfDay(parseDate(whatsOnEvent.startDate))
      const existingDay = dayList.find((day) => isEqual(day.date, dayStartDate))

      if (existingDay) {
        existingDay.items.push(whatsOnEvent)
      } else {
        dayList.push({
          date: dayStartDate,
          isToday: isToday(dayStartDate),
          isTomorrow: isTomorrow(dayStartDate),
          items: [whatsOnEvent],
        })
      }
    })

    return dayList
  },
})
