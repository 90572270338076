import React, { useLayoutEffect, useMemo, useRef, useState, useContext, ReactElement } from "react"
import throttle from "lodash/throttle"
import { EnvironmentContext } from "@geome/react-next/lib/context/environmentContext"
import { TNTSportLocation } from "../../../types"

type StaticMapProps = {
  locationId: TNTSportLocation["id"]
}

export const StaticMap = ({ locationId }: StaticMapProps): ReactElement => {
  const mapRef = useRef<HTMLDivElement | null>(null)
  const [url, setUrl] = useState<string | null>(null)
  const { devicePixelRatio } = useContext(EnvironmentContext)
  const update = useMemo(
    () =>
      throttle(() => {
        if (!mapRef.current) return
        const { width, height } = mapRef.current.getBoundingClientRect()
        const size = `${Math.round(width)}x${Math.round(height)}`
        setUrl(`/api/v2/locations/${locationId}/static_map/${size}?scale=${devicePixelRatio || 2}`)
      }, 1000),
    [locationId, devicePixelRatio]
  )

  useLayoutEffect(() => {
    update()
    window.addEventListener("resize", update)
    return () => window.removeEventListener("resize", update)
  }, [update])

  return (
    <div
      ref={mapRef}
      className="info-window__section info-window__section-static-map"
      style={{
        backgroundImage: `url(${url})`,
      }}
    ></div>
  )
}
