import React, { ReactElement, useMemo } from "react"
import { FilterGroups } from "@geome/react-components-next/lib/components/filters/filterGroups"
import { useUIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import { icons } from "../icons/images"
import { BlockScopingContextProvider } from "@geome/react-next"

export const Filters = (): ReactElement => {
  const { toggle, groups } = useUIFilters()

  const filterIcons = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(icons).map(([key, value]) => [`facilities.${key}`, <img src={value} />])
      ),
    []
  )
  return (
    <BlockScopingContextProvider block="filters">
      <div className="filters">
        <FilterGroups groups={groups} onToggleFilter={toggle} filterIcons={filterIcons} />
      </div>
    </BlockScopingContextProvider>
  )
}
