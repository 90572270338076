import React, { ReactElement } from "react"
import { TNTSportLocation } from "../../../types"
import { SectionWithTitle } from "@geome/react-components-next/lib/components/sectionWithTitle"
import { useRecoilValue } from "@geome/recoil"
import { PlacesOpeningHoursSelectorFamily } from "../../../recoil/places/selectors"
import { useI18n } from "@geome/react-next"

type OpeningHoursProps = {
  locationId: TNTSportLocation["id"]
}

export const OpeningHours = ({ locationId }: OpeningHoursProps): ReactElement | null => {
  const openingHours = useRecoilValue(PlacesOpeningHoursSelectorFamily({ locationId }))
  const { translate } = useI18n()

  if (!openingHours) return null

  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ] as const

  const openingHoursInfo = days.map((day) => {
    // support locations where there's only a single period for every day
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (openingHours.length === 1 && !openingHours[0].dayOfWeek)
      return { day, periods: openingHours }

    const periods = openingHours.filter(({ dayOfWeek }) => dayOfWeek && dayOfWeek.includes(day))
    return { day, periods }
  })

  return (
    <SectionWithTitle id="opening_hours" sectionTokenPath="info_window.sections.opening_hours">
      {openingHoursInfo.map(({ day, periods }) => (
        <div key={day} className="opening-hours__item">
          <div className="opening-hours__day">
            {translate(`info_window.sections.opening_hours.days.${day}`)}
          </div>
          <div className="opening-hours__time">
            {periods.length === 0 && translate("info_window.sections.opening_hours.closed")}
            {periods.map(({ opens, closes }) => (
              <div key={opens}>{`${opens} - ${closes}`}</div>
            ))}
          </div>
        </div>
      ))}
    </SectionWithTitle>
  )
}
