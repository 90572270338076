import { BlockScopingContextProvider, useI18n } from "@geome/react-next"
import { LocationsList as LocationsListBase } from "@geome/react-components-next/lib/components/locationsList"

import React, { ReactElement } from "react"
import { TNTSportLocation } from "../../types"
import { LocationListItem } from "./item"

export const LocationsList = (): ReactElement => {
  const { translate } = useI18n()

  return (
    <BlockScopingContextProvider block="locations-list">
      <LocationsListBase
        endpoint="nearest_to"
        resource="locations"
        emptyFallback={
          <div className="locations-list__empty-message">
            {translate("locations_list.empty_message")}
          </div>
        }
      >
        {(location: TNTSportLocation) => <LocationListItem location={location} key={location.id} />}
      </LocationsListBase>
    </BlockScopingContextProvider>
  )
}
