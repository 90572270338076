import { atom } from "@geome/recoil"
import { WhatsOnView } from "../../types"

export const whatsOnRequestBumpAtom = atom<number>({
  key: "whatsOnRequestBumpAtom",
  default: 0,
})

export const whatsOnViewAtom = atom<WhatsOnView>({
  key: "whatsOnViewAtom",
  default: "today",
})
