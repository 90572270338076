import React, { ReactElement, useMemo } from "react"
import { SectionWithTitle } from "@geome/react-components-next/lib/components/sectionWithTitle"
import { useConfig } from "@geome/react-next"
import { TNTSportLocation } from "../../../types"
import { icons } from "../../icons/images"
import { PropertiesIconList } from "@geome/react-components-next/lib/components/properties/iconList"

type ChannelsSectionProps = {
  location: TNTSportLocation
}

export const ChannelsSection = ({ location }: ChannelsSectionProps): ReactElement | null => {
  const enabled = useConfig<string[]>("info_window.sections.channels_shown.enabled", false)

  const enabledProperties = useMemo(
    () =>
      enabled.filter((property) => {
        const [group, value] = property.split(".")
        return location[group].includes(value)
      }),
    [enabled, location]
  )

  const channelIcons = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(icons).map(([key, value]) => [`channels_shown_${key}`, value])
      ),
    []
  )

  if (enabledProperties.length === 0) return null

  return (
    <SectionWithTitle id="channels" sectionTokenPath="info_window.sections.channels">
      <PropertiesIconList
        properties={enabledProperties}
        countryCode="en_GB"
        icons={channelIcons}
        maxIcons={enabledProperties.length}
      />
    </SectionWithTitle>
  )
}
