import React, { ReactElement, useMemo } from "react"
import { TNTSportLocation } from "../../../types"
import { PropertiesSection } from "@geome/react-components-next/lib/components/properties"
import { icons } from "../../icons/sportImages"
import { useMobileCollapseSection } from "../../../hooks/useMobileCollapseSection"

type FavouriteSportsSectionProps = {
  location: TNTSportLocation
}

export const FavouriteSportsSection = ({ location }: FavouriteSportsSectionProps): ReactElement => {
  const iconImages = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(icons).map(([key, value]) => [`favourite_sports_${key}`, value])
      ),
    []
  )

  const sectionProps = useMobileCollapseSection()

  return (
    <PropertiesSection
      location={location}
      countryCode="en_GB"
      sectionName="favourite_sports"
      configPath="info_window."
      icons={iconImages}
      renderNullIfEmpty
      sectionProps={sectionProps}
    />
  )
}
