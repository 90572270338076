import { SectionWithTitle } from "@geome/react-components-next/lib/components/sectionWithTitle"
import { useI18n, usePublishUserInteraction } from "@geome/react-next"
import React, { ReactElement } from "react"
import { TNTSportLocation } from "../../../types"
import { ContactDetailsSelectorFamily } from "../../../recoil/places/selectors"
import { useRecoilValue } from "@geome/recoil"
import { Facebook } from "../../icons/images"

type ContactSectionProps = {
  location: TNTSportLocation
}

export const ContactSection = ({ location }: ContactSectionProps): ReactElement | null => {
  const contactDetails = useRecoilValue(ContactDetailsSelectorFamily({ locationId: location.id }))
  const [publishWebsiteUserInteraction] = usePublishUserInteraction("website", "click")
  const [publishFacebookUserInteraction] = usePublishUserInteraction("facebook", "click")
  const [publishPhoneUserInteraction] = usePublishUserInteraction("phone", "click")
  const { translate } = useI18n()
  if (!contactDetails) return null

  return (
    <SectionWithTitle id="contact" sectionTokenPath="info_window.sections.contact">
      <div className="info_window__contact-list">
        {contactDetails.phone && (
          <div className="info_window__contact-row">
            <div>{`${translate("info_window.sections.contact.phone")}:`}</div>
            <a
              href={`tel:${contactDetails.phone.international}`}
              onClick={() => publishPhoneUserInteraction({ id: location.id })}
            >
              {contactDetails.phone.formatted}
            </a>
          </div>
        )}
        {contactDetails.website && (
          <div className="info_window__contact-row">
            <div>{`${translate("info_window.sections.contact.website")}:`}</div>
            <a
              className="info_window__contact-link"
              href={contactDetails.website}
              onClick={() => publishWebsiteUserInteraction({ id: location.id })}
              target="_blank"
            >
              {contactDetails.website.replace(/http(s?):\/\//g, "").replace(/(\?(.*)$)/, "")}
            </a>
          </div>
        )}
        {contactDetails.facebook && (
          <a
            className="info_window__facebook-link"
            target="_blank"
            onClick={() => publishFacebookUserInteraction({ id: location.id })}
            href={contactDetails.facebook}
          >
            <img
              src={Facebook}
              alt={translate("info_window.sections.contact.facebook_alt") as string}
            />
          </a>
        )}
      </div>
    </SectionWithTitle>
  )
}
