import AmericanFootball from "../../../images/sport_icons/american_football.svg"
import Athletics from "../../../images/sport_icons/athletics.svg"
import AussieRules from "../../../images/sport_icons/aussie_rules.svg"
import Badminton from "../../../images/sport_icons/badminton.svg"
import Baseball from "../../../images/sport_icons/baseball.svg"
import Basketball from "../../../images/sport_icons/basketball.svg"
import Boxing from "../../../images/sport_icons/boxing.svg"
import Cricket from "../../../images/sport_icons/cricket.svg"
import Cycling from "../../../images/sport_icons/cycling.svg"
import Darts from "../../../images/sport_icons/darts.svg"
import Diving from "../../../images/sport_icons/diving.svg"
import Equestrian from "../../../images/sport_icons/equestrian.svg"
import Esports from "../../../images/sport_icons/esports.svg"
import Fishing from "../../../images/sport_icons/fishing.svg"
import Football from "../../../images/sport_icons/football.svg"
import Formula1 from "../../../images/sport_icons/formula_1.svg"
import Golf from "../../../images/sport_icons/golf.svg"
import Gymnastic from "../../../images/sport_icons/gymnastic.svg"
import Hockey from "../../../images/sport_icons/hockey.svg"
import HorseRacing from "../../../images/sport_icons/horse_racing.svg"
import MotoGP from "../../../images/sport_icons/moto_gp.svg"
import MotoSport from "../../../images/sport_icons/motosport.svg"
import Poker from "../../../images/sport_icons/poker.svg"
import Rowing from "../../../images/sport_icons/rowing.svg"
import RugbyLeague from "../../../images/sport_icons/rugby_league.svg"
import RugbyUnion from "../../../images/sport_icons/rugby_union.svg"
import Sailing from "../../../images/sport_icons/sailing.svg"
import Snooker from "../../../images/sport_icons/snooker.svg"
import Snowboarding from "../../../images/sport_icons/snowboarding.svg"
import Speedway from "../../../images/sport_icons/speedway.svg"
import Squash from "../../../images/sport_icons/squash.svg"
import Swimming from "../../../images/sport_icons/swimming.svg"
import Tennis from "../../../images/sport_icons/tennis.svg"
import UFC from "../../../images/sport_icons/ufc.svg"
import WWE from "../../../images/sport_icons/wwe.svg"

export const icons = {
  american_football: AmericanFootball,
  athletics: Athletics,
  aussie_rules: AussieRules,
  badminton: Badminton,
  baseball: Baseball,
  basketball: Basketball,
  boxing: Boxing,
  cricket: Cricket,
  cycling: Cycling,
  darts: Darts,
  diving: Diving,
  equestrian: Equestrian,
  esports: Esports,
  fishing: Fishing,
  football: Football,
  formula_1: Formula1,
  golf: Golf,
  gymnastic: Gymnastic,
  hockey: Hockey,
  horse_racing: HorseRacing,
  moto_gp: MotoGP,
  motosport: MotoSport,
  poker: Poker,
  rowing: Rowing,
  rugby_league: RugbyLeague,
  rugby_union: RugbyUnion,
  sailing: Sailing,
  snooker: Snooker,
  snowboarding: Snowboarding,
  speedway: Speedway,
  squash: Squash,
  swimming: Swimming,
  tennis: Tennis,
  ufc: UFC,
  wwe: WWE,
}

export {
  AmericanFootball,
  Athletics,
  AussieRules,
  Badminton,
  Baseball,
  Basketball,
  Boxing,
  Cricket,
  Cycling,
  Darts,
  Diving,
  Equestrian,
  Esports,
  Fishing,
  Football,
  Formula1,
  Golf,
  Gymnastic,
  Hockey,
  HorseRacing,
  MotoGP,
  MotoSport,
  Poker,
  Rowing,
  RugbyLeague,
  RugbyUnion,
  Sailing,
  Snooker,
  Snowboarding,
  Speedway,
  Squash,
  Swimming,
  Tennis,
  UFC,
  WWE,
}
