import { LocationsListItem as LocationsListItemBase } from "@geome/react-components-next/lib/components/locationsList/item"
import React, { ReactElement, Suspense, useEffect, useState } from "react"
import { TNTSportLocation } from "../../types"
import { useI18n } from "@geome/react-next"
import { useInView } from "react-intersection-observer"
import { PlacesImage } from "../placesImage"
import { SportsIconList } from "../sportsIconList"

export const LocationListItem = ({ location }: { location: TNTSportLocation }): ReactElement => {
  const { translate } = useI18n()
  const { ref, inView } = useInView()

  const [hasBeenInView, setHasBeenInView] = useState<boolean>(false)

  useEffect(() => {
    if (inView && !hasBeenInView) setHasBeenInView(true)
  }, [inView, hasBeenInView])

  return (
    <LocationsListItemBase
      location={location}
      resource="locations"
      renderContent={() => (
        <>
          <div ref={ref} className="locations-list__item-image-container">
            <Suspense fallback={null}>
              {hasBeenInView && location.google_places_id && (
                <PlacesImage key={location.id} locationId={location.id} />
              )}
            </Suspense>
          </div>
          <div className="locations-list__item-info">
            <div className="locations-list__item-name">{location.name}</div>
            <div>{location.formatted_address}</div>
            <SportsIconList location={location} maxIcons={4} />
            <div className="locations-list__item-distance">
              {translate("distance", { distance: location.distance, convert: true })}
            </div>
          </div>
        </>
      )}
    />
  )
}
