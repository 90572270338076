import { useI18n } from "@geome/react-next"
import React, { ReactElement } from "react"

type LoadingProps = {
  className?: string
}

export const Loading = ({ className = "" }: LoadingProps): ReactElement => {
  const { translate } = useI18n()

  return <div className={`loading ${className}`}>{translate("loading.text")}</div>
}
