import { useI18n, usePublishUserInteraction } from "@geome/react-next"
import React, { ReactElement } from "react"

export function Tabs<const T extends string>({
  tabs,
  selectedTab,
  onClickTab,
  translatePrefix,
  className,
}: {
  tabs: T[]
  selectedTab: T
  onClickTab: (tab: T) => void
  translatePrefix: string
  className: string
}): ReactElement {
  const { translate } = useI18n()
  const [publishUserInteraction] = usePublishUserInteraction("tab", "select")

  return (
    <div className={`tabs ${className}`} role="tablist">
      {tabs.map((tab) => (
        <button
          role="tab"
          aria-selected={tab === selectedTab}
          aria-controls={`tab-panel-${tab}`}
          id={`tab-button-${tab}`}
          className={`tabs__tab ${tab === selectedTab ? "tabs__tab--selected" : ""}`}
          key={tab}
          onClick={() => {
            publishUserInteraction({ tab })
            onClickTab(tab)
          }}
        >
          {translate(`${translatePrefix}.${tab}`)}
        </button>
      ))}
    </div>
  )
}
