import { useSubscribeToUserInteraction } from "@geome/react-next"
import { Filter, Lat, Lng, SelectionIntent } from "@geome/types"
import { TNTSportLocation } from "../../types"

export const Analytics = (): null => {
  useSubscribeToUserInteraction<Filter>("filters:filterGroups:toggle", (_topic, additionalData) => {
    if (additionalData.active)
      window.gtag("event", "filter", {
        filter: additionalData.filterName,
        group: additionalData.groupName,
      })
  })

  useSubscribeToUserInteraction<{ location: TNTSportLocation }>(
    "*:location:select",
    (_topic, additionalData): void => {
      window.gtag("event", "select_location", { id: additionalData.location.id })
    }
  )

  useSubscribeToUserInteraction<{ completion: SelectionIntent }>(
    "search:completion:select",
    (_topic, additionalData) => {
      window.gtag("event", "search", { query: additionalData.completion.description })
    }
  )

  useSubscribeToUserInteraction<{ tab: string }>("*:tab:select", (_topic, additionalData): void => {
    window.gtag("event", "change_tab", { tab: additionalData.tab })
  })

  useSubscribeToUserInteraction<{ id: TNTSportLocation["id"] }>(
    "info-window:phone:click",
    (_topic, additionalData) => {
      window.gtag("event", "click_phone", { id: additionalData.id })
    }
  )

  useSubscribeToUserInteraction<{ id: TNTSportLocation["id"] }>(
    "info-window:website:click",
    (_topic, additionalData) => {
      window.gtag("event", "click_website", { id: additionalData.id })
    }
  )

  useSubscribeToUserInteraction<{
    id: TNTSportLocation["id"]
  }>("info-window:facebook:click", (_topic, additionalData) => {
    window.gtag("event", "click_facebook", { id: additionalData.id })
  })

  useSubscribeToUserInteraction<{
    id: TNTSportLocation["id"]
    lat: Lat
    lng: Lng
    placeId?: TNTSportLocation["google_places_id"]
  }>("info-window:directions:click", (_topic, additionalData) => {
    window.gtag("event", "click_directions", { id: additionalData.id })
  })

  return null
}
