import React, { ReactElement, Suspense, useEffect, useState } from "react"
import { useIsMobile } from "../../hooks/useIsMobile"
import { Map } from "../map"
import { Filters } from "../filters"
import { WhatsOn } from "../whatsOn"
import { LocationsList } from "../locationsList"
import { Tabs } from "../tabs"
import { AppTabs } from "../../types"
import { TabPanel } from "../tabs/panel"
import { Search } from "../search"
import { useLocations, useSelectedLocation } from "@geome/recoil/dist/feature/locations/hooks"
import { MobileFilterButton } from "../filters/mobileButton"
import { Loading } from "../loading"

export const AppGrid = (): ReactElement => {
  const isMobile = useIsMobile()
  const [currentTab, setCurrentTab] = useState<AppTabs>("locations_list")
  const selectedLocation = useSelectedLocation()

  useSwitchToLocationsListTabOnLocations(currentTab, setCurrentTab)

  return (
    <div
      className="app"
      aria-hidden={selectedLocation !== null}
      tabIndex={selectedLocation !== null ? -1 : undefined}
    >
      {!isMobile && <Map />}
      <Search />
      {isMobile ? <MobileFilterButton /> : <Filters />}

      <Tabs
        className="app__tabs-nav"
        tabs={isMobile ? ["map", "locations_list"] : ["locations_list"]}
        selectedTab={currentTab}
        onClickTab={(tab: AppTabs) => setCurrentTab(tab)}
        translatePrefix="tabs"
      />

      <TabPanel
        className="app__tab-content"
        selectedTab={currentTab}
        tabContents={
          isMobile
            ? {
                map: <Map />,
                whats_on: (
                  <Suspense fallback={<Loading />}>
                    <WhatsOn />
                  </Suspense>
                ),
                locations_list: <LocationsList />,
              }
            : {
                whats_on: (
                  <Suspense fallback={<Loading />}>
                    <WhatsOn />
                  </Suspense>
                ),
                locations_list: <LocationsList />,
              }
        }
      />
    </div>
  )
}

const useSwitchToLocationsListTabOnLocations = (
  currentTab: AppTabs,
  setCurrentTab: (tab: AppTabs) => void
): void => {
  const locations = useLocations({ resource: "locations", endpoint: "nearest_to" })
  const [shouldSwitch, setShouldSwitch] = useState<boolean>(true)

  useEffect(() => {
    if (shouldSwitch && locations.length > 0) {
      setShouldSwitch(false)
      setCurrentTab("locations_list")
    }
  }, [shouldSwitch, locations, setCurrentTab])

  useEffect(() => {
    if (currentTab !== "whats_on" && shouldSwitch) {
      setShouldSwitch(false)
    }
  }, [currentTab, shouldSwitch])
}
