import { SectionWithTitle } from "@geome/react-components-next/lib/components/sectionWithTitle"
import React, { ComponentProps, useMemo } from "react"
import { useIsMobile } from "./useIsMobile"
import { ChevronIcon } from "@geome/react-components-next/lib/components/icons/chevron"

type Props = Pick<ComponentProps<typeof SectionWithTitle>, "collapsible">

export const useMobileCollapseSection = (): Props => {
  const isMobile = useIsMobile()

  return useMemo<Props>(
    () =>
      isMobile
        ? {
            collapsible: {
              openByDefault: isMobile,
              renderIcon: (open) => (
                <div style={{ marginLeft: "auto" }}>
                  <ChevronIcon strokeWidth={2} direction={open ? "up" : "down"} />
                </div>
              ),
            },
          }
        : {},
    [isMobile]
  )
}
