import React, { ReactElement } from "react"

export const FilterIcon = (): ReactElement => (
  <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.9668 17.25H15.4668V14.5H9.9668V17.25ZM0.341797 0.75V3.5H25.0918V0.75H0.341797ZM4.4668 10.375H20.9668V7.625H4.4668V10.375Z"
      fill="currentColor"
    />
  </svg>
)
