import React from "react"
import { createRoot } from "react-dom/client"
import { unregisterHighTideServiceWorker } from "@geome/hightide-service-worker"
import { App } from "./components/app"

// The `non_matching_locations` response has fake clusters
// Can re-enable if it gets real clusters at some point
unregisterHighTideServiceWorker()

import "../iframe-test.html"

const render = (): void => {
  const container = document.getElementById("app")
  if (!container) throw Error("`.app` element missing")
  const root = createRoot(container)
  root.render(<App />)
}

document.addEventListener("DOMContentLoaded", () => render())
