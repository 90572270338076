import { useI18n } from "@geome/react-next"
import React, { ReactElement, useId, useState } from "react"
import { FilterIcon } from "../icons/filter"
import { Filters } from "."

export const MobileFilterButton = (): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dialogId = useId()
  const { translate } = useI18n()
  return (
    <>
      <button
        className="filters__mobile-button"
        aria-label={translate("filters.open_button") as string}
        onClick={() => setIsOpen(true)}
        aria-controls={dialogId}
      >
        <FilterIcon />
      </button>

      <dialog
        open={isOpen}
        id={dialogId}
        className="filters__mobile-dialog"
        style={{ display: isOpen ? "flex" : undefined }}
      >
        <div className="filters__mobile-dialog-title">
          {translate("filters.title")}

          <button
            className="filters__close-button"
            aria-label={translate("filters.close_button") as string}
            onClick={() => setIsOpen(false)}
          ></button>
        </div>
        <Filters />
      </dialog>
    </>
  )
}
