import { useRecoilValue } from "@geome/recoil"
import React, { ReactElement, useLayoutEffect, useRef, useState } from "react"
import { TNTSportLocation } from "../../../../types"
import { ReviewsSelectorFamily } from "../../../../recoil/places/selectors"
import { ItemCarousel } from "@geome/react-components-next/lib/components/itemCarousel"
import { StarIcon } from "../../../icons/star"
import { GoogleIcon } from "../../../icons/google"
import { useI18n } from "@geome/react-next"

type ReviewsSectionProps = {
  locationId: TNTSportLocation["id"]
}

export const ReviewsSection = ({ locationId }: ReviewsSectionProps): ReactElement | null => {
  const { reviews, overallRating } = useRecoilValue(ReviewsSelectorFamily({ locationId }))
  if (reviews.length === 0 || !overallRating) return null

  return (
    <ItemCarousel
      perPage={1}
      sectionName="reviews"
      configPath="info_window."
      renderBefore={() => (
        <div className="reviews__overall-rating">
          <GoogleIcon />
          {overallRating}
          <StarRating rating={overallRating} />
        </div>
      )}
    >
      {reviews.map(({ author_name, text, rating, relative_time_description }) => (
        <div className="reviews__review" key={text}>
          <div className="reviews__review-name">{author_name}</div>
          <div className="reviews__review-rating-time">
            <StarRating rating={rating} />
            {relative_time_description}
          </div>
          <CollapsableText className="reviews__review-text" text={text} maxHeight={120} />
        </div>
      ))}
    </ItemCarousel>
  )
}

const StarRating = ({ rating }: { rating: number }): ReactElement => {
  const { translate } = useI18n()

  return (
    <div
      className="reviews__star-rating"
      aria-label={translate("info_window.sections.reviews.aria.stars", { rating }) as string}
    >
      {Array(5)
        .fill(0)
        .map((_, index) => index + 1)
        .map((_, i) => (
          <StarIcon key={i} filled={Math.floor(rating) > i} />
        ))}
    </div>
  )
}

const CollapsableText = ({
  text,
  className,
  maxHeight,
}: {
  text: string
  className: string
  maxHeight: number
}): ReactElement => {
  const [shouldCollapse, setShouldCollapse] = useState<boolean | null>(null)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const { translate } = useI18n()
  const textRef = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (!textRef.current) return
    if (shouldCollapse !== null) return
    const { height } = textRef.current.getBoundingClientRect()
    const wiggleRoom = 20
    if (height > maxHeight + wiggleRoom && !isCollapsed) {
      setShouldCollapse(true)
      setIsCollapsed(true)
    }
  }, [isCollapsed, maxHeight, shouldCollapse])

  return (
    <div
      ref={textRef}
      className={`${className} ${isCollapsed ? `${className}--collapsed` : ""} `}
      style={{
        height: isCollapsed ? `${maxHeight}px` : undefined,
        paddingBottom: shouldCollapse && !isCollapsed ? "20px" : undefined,
        overflow: "hidden",
        position: "relative",
      }}
    >
      {text}
      {shouldCollapse && (
        <button
          aria-hidden
          className="review__collapse-button"
          style={{ position: "absolute", bottom: 0, right: 0 }}
          onClick={() => setIsCollapsed((c) => !c)}
        >
          {translate(`info_window.sections.reviews.buttons.${isCollapsed ? "more" : "less"}`)}
        </button>
      )}
    </div>
  )
}
