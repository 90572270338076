import React, { ReactElement, useLayoutEffect, useRef, useState } from "react"
import { TNTSportLocation } from "../../types"
import { useRecoilValue } from "@geome/recoil"
import { PlacesImageSrcSelectorFamily } from "../../recoil/places/selectors"

type PlacesImageProps = {
  locationId: TNTSportLocation["id"]
}

export const PlacesImage = ({ locationId }: PlacesImageProps): ReactElement | null => {
  const imageSrc = useRecoilValue(PlacesImageSrcSelectorFamily({ locationId }))
  const imageRef = useRef<HTMLImageElement | null>(null)
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (!imageRef.current) return
    imageRef.current.onload = () => setHasLoaded(true)
  }, [imageRef])

  if (!imageSrc) return null
  return (
    <img
      ref={imageRef}
      className={`places-image ${hasLoaded ? "places-image--loaded" : ""}`}
      src={imageSrc}
    ></img>
  )
}
