import React from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "@geome/recoil"
import {
  groupedWhatsOnItemsSelector,
  whatsOnHasErroredSelector,
} from "../../recoil/whatsOn/selectors"
import { ReactElement } from "react"
import { whatsOnRequestBumpAtom, whatsOnViewAtom } from "../../recoil/whatsOn/atoms"
import { WhatsOnItem, WhatsOnView } from "../../types"
import { format, parse } from "date-fns"
import { BlockScopingContextProvider, useI18n, usePublishUserInteraction } from "@geome/react-next"

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXX"
const TABS = [
  "today",
  "week",
  "thirty",
  "dont_miss",
  "all",
] as const satisfies readonly WhatsOnView[]

export const WhatsOn = (): ReactElement => {
  const [whatsOnView, setWhatsOnView] = useRecoilState(whatsOnViewAtom)
  const hasErrored = useRecoilValue(whatsOnHasErroredSelector)
  const { translate } = useI18n()

  return (
    <BlockScopingContextProvider block="whats-on">
      <div className="whats-on">
        <div className="whats-on__tabs">
          {TABS.map((tab) => (
            <div
              className={`whats-on__tab ${tab === whatsOnView ? "whats-on__tab--selected" : ""}`}
              key={tab}
              onClick={() => setWhatsOnView(tab)}
            >
              {translate(`whats_on.tabs.${tab}`)}
            </div>
          ))}
        </div>
        {hasErrored ? <WhatsOnRequestError /> : <WhatsOnEventList />}
      </div>
    </BlockScopingContextProvider>
  )
}

const WhatsOnRequestError = (): ReactElement => {
  const { translate } = useI18n()
  const setRequestBump = useSetRecoilState(whatsOnRequestBumpAtom)
  const [publishUserInteraction] = usePublishUserInteraction("try-again", "click")

  return (
    <div className="whats-on__error">
      {translate("whats_on.error_message.text", {
        a: (text) => (
          <a className="whats-on__error-link" href={text as unknown as string}>
            {text}
          </a>
        ),
      })}
      <button
        className="whats-on__error-button"
        onClick={() => {
          setRequestBump(Date.now())
          publishUserInteraction()
        }}
      >
        {translate("whats_on.error_message.button")}
      </button>
    </div>
  )
}

const WhatsOnEventList = (): ReactElement => {
  const groupedWhatsOnItems = useRecoilValue(groupedWhatsOnItemsSelector)
  const { translate } = useI18n()

  if (groupedWhatsOnItems.length === 0) {
    return <div>{translate("whats_on.no_items")}</div>
  }

  return (
    <ol className="whats-on__day-list">
      {groupedWhatsOnItems.map(({ date, isToday, isTomorrow, items }) => (
        <li key={date.toString()}>
          <div className="whats-on__day-title" key={date.toString()}>
            {isToday && translate("whats_on.today")}
            {isTomorrow && translate("whats_on.tomorrow")}
            {!isToday && !isTomorrow && format(date, "E do MMMM YYY")}
          </div>

          <ol className="whats-on__event-list">
            {items.map((item) => (
              <WhatsOnEventItem key={item.name} item={item} />
            ))}
          </ol>
        </li>
      ))}
    </ol>
  )
}

type WhatsOnEventItemProps = {
  item: WhatsOnItem
}

const WhatsOnEventItem = ({ item }: WhatsOnEventItemProps): ReactElement => {
  const { title, startDate, competition, sport, channels } = item

  return (
    <li className="whats-on__event-item" key={`${title}@${startDate}`}>
      <div className="whats-on__event-item-time">
        {format(parse(startDate, DATE_FORMAT, new Date()), "HH:mm")}
      </div>
      <div className="whats-on__event-item-title">{title}</div>
      <div className="whats-on__event-item-info-extra">
        {[competition, sport].filter(Boolean).map((text) => (
          <div className="whats-on__event-item-info-extra-item" key={text}>
            {text}
          </div>
        ))}
      </div>
      <div className="whats-on__event-item-channels">
        {channels.map(({ name, logo }) => (
          <img
            className="whats-on__event-item-channels-item"
            key={logo}
            src={logo}
            alt={name}
            height="16"
          />
        ))}
      </div>
    </li>
  )
}
