import { SectionWithTitle } from "@geome/react-components-next/lib/components/sectionWithTitle"
import React, { ReactElement } from "react"
import { TNTSportLocation } from "../../../types"

type AboutSectionProps = {
  location: TNTSportLocation
}

export const AboutSection = ({ location }: AboutSectionProps): ReactElement | null => {
  if (!location.about) return null

  return (
    <SectionWithTitle id="about" sectionTokenPath="info_window.sections.about">
      <p>{location.about}</p>
    </SectionWithTitle>
  )
}
