import { useLocations } from "@geome/recoil/dist/feature/locations/hooks"
import React, { ReactElement } from "react"
import { TNTSportMarker } from "@geome/markers"

export const Locations = (): ReactElement => {
  const locations = useLocations({
    resource: "locations",
    endpoint: "within_bounds",
  })

  const nonMatchingLocatons = useLocations({
    resource: "non_matching_locations",
    endpoint: "within_bounds",
  })

  return (
    <>
      {locations.map((location) => (
        <TNTSportMarker key={location.id} location={location} resource="locations" />
      ))}
      {nonMatchingLocatons.map((location) => (
        <TNTSportMarker key={location.id} location={location} resource="non_matching_locations" />
      ))}
    </>
  )
}
