import React, { ReactElement } from "react"

type StarIconProps = {
  filled: boolean
}

export const StarIcon = ({ filled }: StarIconProps): ReactElement => (
  <svg
    width="17"
    aria-hidden={true}
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.34212 13.3715L13.4921 16.4798L12.1254 10.6215L16.6754 6.67982L10.6838 6.17148L8.34212 0.646484L6.00046 6.17148L0.00878906 6.67982L4.55879 10.6215L3.19212 16.4798L8.34212 13.3715Z"
      fill={filled ? "currentColor" : undefined}
      stroke="currentColor"
    />
  </svg>
)
