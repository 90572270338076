import React from "react"
import { ConfigContextProvider } from "@geome/react-next/lib/context/config"
import { GoogleMapsLoaderUsingConfig } from "@geome/react-components-next/lib/components/map/google/loader"
import { config } from "../../config"
import { Resources } from "../resources"
import { I18nContextProvider } from "@geome/react-next/lib/context/i18nContext/provider"
import { initialiseFilterState } from "@geome/recoil/dist/feature/filters/initialisers"
import { initialiseDirectionsOptions } from "@geome/recoil/dist/feature/directions/initialisers"
import { initialiseResourcesState } from "@geome/recoil/dist/feature/resources/initialisers"
import { RecoilMultiInitRoot } from "@geome/recoil"
import { GeospatialApiRequesterContextProvider } from "@geome/geospatial-api-requester"
import { CSSCustomPropertyContextProvider } from "@geome/react-next/lib/context/cssCustomProperty"
import DistanceFormatHelper from "@geome/intl/lib/formatHelper/distance"
import { UserInteractionContextProvider } from "@geome/react-next/lib/context/userInteraction"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { setSelectionIntentFromURL } from "@geome/recoil/dist/feature/selection/initalisers/fromURL"
import { LocatorAppConfig } from "../../types"
import { AppGrid } from "./grid"
import { InfoWindow } from "../infoWindow"
import { nearestToModeAtom } from "@geome/recoil/dist/feature/locations/atoms"
import { Analytics } from "../analytics"

const App = (): JSX.Element => (
  <ConfigContextProvider value={config as LocatorAppConfig}>
    {(finalConfig: LocatorAppConfig) => (
      <RecoilMultiInitRoot
        initialisers={[
          initialiseFilterState(finalConfig),
          initialiseResourcesState(finalConfig),
          initialiseDirectionsOptions(finalConfig),
          setSelectionIntentFromURL,
          ({ set }) => set(nearestToModeAtom, "EMPTY_IF_CLUSTERED_WITHOUT_SEARCH"),
        ]}
      >
        <UserInteractionContextProvider>
          <GeospatialApiRequesterContextProvider
            extraParams={
              finalConfig.locationIds ? { location_ids: finalConfig.locationIds } : undefined
            }
          >
            <I18nContextProvider
              intlData={finalConfig.intlData}
              locale={finalConfig.locale}
              intlExplorer={finalConfig.intlExplorer}
              mode={process.env.NODE_ENV !== "prod" ? "development" : "production"}
              formatHelpers={[DistanceFormatHelper]}
            >
              <GoogleMapsLoaderUsingConfig>
                <CSSCustomPropertyContextProvider
                  properties={{
                    "--primary-colour": "#FF28FF",
                    "--hover-colour": "#ffdbff",
                    "--text-dark-colour": "#101E28",
                  }}
                >
                  <BlockScopingContextProvider block="app">
                    <AppGrid />
                    <InfoWindow />
                    <Resources />
                    <Analytics />
                  </BlockScopingContextProvider>
                </CSSCustomPropertyContextProvider>
              </GoogleMapsLoaderUsingConfig>
            </I18nContextProvider>
          </GeospatialApiRequesterContextProvider>
        </UserInteractionContextProvider>
      </RecoilMultiInitRoot>
    )}
  </ConfigContextProvider>
)

export { App }
