import React, { ReactElement } from "react"

export const GoogleIcon = (): ReactElement => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1726 6.72957H16.5013V6.69499H9.0013V10.0283H13.7109C13.0238 11.9687 11.1776 13.3616 9.0013 13.3616C6.24005 13.3616 4.0013 11.1229 4.0013 8.36163C4.0013 5.60041 6.24005 3.36166 9.0013 3.36166C10.2759 3.36166 11.4355 3.84249 12.3184 4.62791L14.6755 2.27082C13.1871 0.88374 11.1963 0.0283203 9.0013 0.0283203C4.39922 0.0283203 0.667969 3.75957 0.667969 8.36163C0.667969 12.9637 4.39922 16.695 9.0013 16.695C13.6034 16.695 17.3346 12.9637 17.3346 8.36163C17.3346 7.80291 17.2771 7.25749 17.1726 6.72957Z"
      fill="#FFC107"
    />
    <path
      d="M1.62891 4.4829L4.36682 6.49082C5.10766 4.65666 6.90182 3.36166 9.0014 3.36166C10.276 3.36166 11.4356 3.84249 12.3185 4.6279L14.6756 2.27082C13.1872 0.88374 11.1964 0.0283203 9.0014 0.0283203C5.80057 0.0283203 3.02474 1.83541 1.62891 4.4829Z"
      fill="#FF3D00"
    />
    <path
      d="M9.0008 16.6949C11.1533 16.6949 13.1091 15.8712 14.5879 14.5316L12.0087 12.3491C11.1439 13.0067 10.0872 13.3624 9.0008 13.3616C6.83328 13.3616 4.99286 11.9795 4.29953 10.0508L1.58203 12.1445C2.9612 14.8433 5.76203 16.6949 9.0008 16.6949Z"
      fill="#4CAF50"
    />
    <path
      d="M17.1713 6.72892H16.5V6.69434H9V10.0276H13.7096C13.3809 10.9511 12.7889 11.7581 12.0067 12.3489L12.0079 12.3481L14.5871 14.5306C14.4046 14.6964 17.3333 12.5276 17.3333 8.361C17.3333 7.80225 17.2758 7.25684 17.1713 6.72892Z"
      fill="#1976D2"
    />
  </svg>
)
