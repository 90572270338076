import React, { ReactElement, Suspense } from "react"
import { BlockScopingContextProvider, useI18n, usePublishUserInteraction } from "@geome/react-next"
import { useSelectedLocation } from "@geome/recoil/dist/feature/locations/hooks"
import { useResetRecoilState } from "@geome/recoil"
import { selectedLocationIdAtom } from "@geome/recoil/dist/feature/locations/atoms"
import { TNTSportLocation } from "../../types"
import { useGoogleDirectionsLink } from "../../hooks/useGoogleDirectionsURL"
import { OpeningHours } from "./sections/openingHours"
import { FacilitiesSection } from "./sections/facilitiesSection"
import { AboutSection } from "./sections/aboutSection"
import { StaticMap } from "./sections/staticMap"
import { ContactSection } from "./sections/contactSection"
import { useIsMobile } from "../../hooks/useIsMobile"
import { ReviewsSection } from "./sections/reviewsSection"
import { FavouriteSportsSection } from "./sections/favouriteSportsSection"
import { ChannelsSection } from "./sections/channelsSection"

export const InfoWindow = (): ReactElement | null => {
  const isMobile = useIsMobile()
  const selectedLocation = useSelectedLocation<TNTSportLocation>()
  const closeInfoWindow = useResetRecoilState(selectedLocationIdAtom)
  const { translate } = useI18n()

  if (!selectedLocation) return null

  return (
    <BlockScopingContextProvider block="info-window">
      <div className="info-window">
        <button className="info-window__back-button" onClick={closeInfoWindow}>
          {translate("info_window.back_button")}
        </button>
        <div className="info-window__title">
          <div className="info-window__title-content">
            <h1 className="info-window__name">{selectedLocation.name}</h1>
            <div className="info-window__address">{selectedLocation.formatted_address}</div>
            <InfoWindowDirectionsButton location={selectedLocation} />
          </div>
        </div>
        {isMobile ? (
          <div className="info-window__content">
            <Suspense fallback={null}>
              <OpeningHours locationId={selectedLocation.id} />
              <ContactSection location={selectedLocation} />
            </Suspense>
            <AboutSection location={selectedLocation} />
            <FacilitiesSection location={selectedLocation} />
            <FavouriteSportsSection location={selectedLocation} />
            <ChannelsSection location={selectedLocation} />
            {/* <WhatsOnSection /> */}
            <StaticMap locationId={selectedLocation.id} />
            <Suspense fallback={null}>
              <ReviewsSection locationId={selectedLocation.id} />
            </Suspense>
          </div>
        ) : (
          <div className="info-window__content">
            <div className="info-window__content-left">
              <FacilitiesSection location={selectedLocation} />
              <ChannelsSection location={selectedLocation} />
              <FavouriteSportsSection location={selectedLocation} />
              {/* <WhatsOnSection /> */}
            </div>

            <div className="info-window__content-right">
              <Suspense fallback={null}>
                <OpeningHours locationId={selectedLocation.id} />
              </Suspense>
              <AboutSection location={selectedLocation} />
              <Suspense fallback={null}>
                <ContactSection location={selectedLocation} />
              </Suspense>
              <StaticMap locationId={selectedLocation.id} />
              <Suspense fallback={null}>
                <ReviewsSection locationId={selectedLocation.id} />
              </Suspense>
            </div>
          </div>
        )}
      </div>
    </BlockScopingContextProvider>
  )
}

const InfoWindowDirectionsButton = ({ location }: { location: TNTSportLocation }): ReactElement => {
  const { translate } = useI18n()
  const directionsURL = useGoogleDirectionsLink({
    lat: location.lat,
    lng: location.lng,
    placeId: location.google_places_id,
  })
  const [publishUserInteraction] = usePublishUserInteraction("directions", "click")
  return (
    <a
      className="info-window__get-directions"
      target="__blank"
      href={directionsURL}
      onClick={() =>
        publishUserInteraction({
          lat: location.lat,
          lng: location.lng,
          placeId: location.google_places_id,
          id: location.id,
        })
      }
    >
      {translate("info_window.get_directions")}
    </a>
  )
}
