import React, { ReactElement, ReactNode } from "react"

export const TabPanel = <T extends Record<string, ReactNode>>({
  selectedTab,
  tabContents,
  className,
}: {
  selectedTab: keyof T
  tabContents: T
  className: string
}): ReactElement => (
  <div className={`tab-panel ${className}`}>
    {Object.entries(tabContents).map(([tab, content]) => (
      <div
        id={`tab-panel-${tab}`}
        key={tab}
        aria-labelledby={`tab-button-${tab}`}
        role="tabpanel"
        aria-hidden={selectedTab !== tab}
        style={{ visibility: selectedTab !== tab ? "hidden" : "visible" }}
      >
        {content}
      </div>
    ))}
  </div>
)
